export default {
    emotions: [
        {
            id: '21314',
            name: 'Feliz',
            prepostion: 'com',
            slug: 'happy',
            active: true,
            createdAt: '2022/10/09',
            createdby: 'venzel'
        },
        {
            id: '21315',
            name: 'Medo',
            prepostion: 'de',
            slug: 'happy',
            active: true,
            createdby: 'venzel',
            createdAt: '2022/10/09'
        }
    ]
};
